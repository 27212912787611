<template>
  <div class="pa-5">
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <Header></Header>
    <v-card class="mx-auto" width="30%" min-width="250px">
      <v-card-title v-t="'Login.Connection'">
      </v-card-title>
      <v-form class="pa-4">
        <v-row>
          <v-col>
            <v-text-field v-model="username" outlined :label="$t('Login.Username')" @keyup.enter="login"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            outlined
            :label="$t('Login.Password')"
            :append-outer-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-outer="togglePasswordVisibility"
            @keyup.enter="login"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="login" v-t="'General.Confirm'"></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import EventBus from '../bus.js'
import Header from '../layouts/Header.vue'

export default {
  name: 'Login',
  components: {
    Header
  },
  data () {
    return {
      username: '',
      password: '',
      loading: false,
      showPassword: false
    }
  },
  created () {
    EventBus.$emit('hideLeftNav')
  },
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('login', { username: this.username, password: this.password }).then(() => {
        this.$router.push({ name: 'Sites' })
        this.$emit('notify', {
          color: 'green',
          text: this.$t('Login.SuccessfulLogin')
        })
        EventBus.$emit('showLeftNav')
      }).catch(() => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.InvalidCredentials')
        })
      }).finally(() => {
        this.loading = false
      })
    },
    togglePasswordVisibility () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>

<style scoped>

</style>
