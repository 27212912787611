<template>
    <div>
      <v-overlay v-if="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-app-bar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          :clipped-right="$vuetify.breakpoint.lgAndUp"
          dark
          app
          prominent
          src="https://dalvin.eu/pub/media/themelem/back_high.jpg"
      >
        <v-toolbar-title>
          <div v-if="currentRouteName !== 'ThreeScene'" v-t="'General.3DConfiguratorAdministration'" class="hidden-sm-and-down"></div>
          <div v-else>
            <v-btn light v-t="'General.Return'" @click="$router.push({ name: 'Products' })"></v-btn>
            <v-btn light v-t="'General.Save'" @click="eventBus.$emit('sauvegarder')" class="ma-2"></v-btn>
            <v-btn light v-t="'General.SaveQuit'" @click="eventBus.$emit('sauvegarderquitter')"></v-btn>
            <v-checkbox v-model="checked" @click="eventBus.$emit('toggleReperes')" :label="$t('General.ShowGuides')"></v-checkbox>
          </div>
        </v-toolbar-title>
        <v-spacer>
        </v-spacer>
        <div v-if="$store.getters['GET_IS_AUTHENTICATED']()">
          <h4>Session : {{ $store.getters['GET_USERNAME']() }}</h4>
          <v-btn light v-t="'General.Disconnect'" @click="logout"></v-btn>
        </div>
      </v-app-bar>
      <v-main>
        <router-view @notify="notify"/>
      </v-main>
      <v-snackbar
          v-model="snackbar"
          :timeout="5000"
          :color="colorSnackbar"
      >
        {{ textSnackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>

  <script>
  import EventBus from '../bus.js'

  export default {
    name: 'HomeLayout',
    data () {
      return {
        eventBus: EventBus,
        snackbar: false,
        textSnackbar: '',
        colorSnackbar: 'blue',
        drawerLeft: true,
        checked: false,
        loading: false
      }
    },
    created () {
      EventBus.$on('showLeftNav', () => {
        this.drawerLeft = true
      })
      EventBus.$on('hideLeftNav', () => {
        this.drawerLeft = false
      })
      EventBus.$on('reperesOff', () => {
        this.checked = false
      })
      if (this.$route.path === '/') {
        this.$router.push({ name: 'Sites' })
      }
    },
    computed: {
      isAuthenticated () {
        return this.$store.state.isAuthenticated
      },
      currentRouteName () {
        return this.$route.path.split('/')[1]
      }
    },
    watch: {
      isAuthenticated (newdata, old) {
        this.$forceUpdate()
      }
    },
    methods: {
      logout () {
        this.loading = true
        this.$store.dispatch('logout').then(() => {
          this.drawerLeft = !this.drawerLeft
          this.loading = false
          this.$store.commit('SET_IS_AUTHENTICATED', false)
          this.$store.commit('SET_ROLES', [])
          this.$store.commit('SET_USERNAME', '')
          this.$router.push({ name: 'Login' })
        })
      },
      selectItem (item) {
        if (item.path && this.$route.path !== item.path) {
          this.$router.push(item.path)
        }
      },
      notify (info) {
        this.textSnackbar = info.text
        this.colorSnackbar = info.color
        this.snackbar = true
      }
    }
  }
  </script>

  <style scoped>

  .language-toggle-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    max-width: fit-content;
  }

  .language-toggle-button .language-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .language-toggle-button .language-text {
    font-weight: bold;
  }

  </style>
